/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, { FC } from 'react';
import Joyride from 'react-joyride';
import { shallowEqual } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/services/redux/app/store';
import { _setJoyride } from 'src/services/redux/slices/auth/auth-slice';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { Logout, AuthPage } from '../modules/auth';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import CancelRefund from '../pages/contracts/cancel-refund';
import DistanceSalesContract from '../pages/contracts/distance-sales-contract';
import PrivacyAndSecurity from '../pages/contracts/privacy-and-security';
import LatePayment from '../pages/late-payment';
import Maintance from '../pages/maintance';
import steps from '../utils/joyride';
import { PrivateRoutes } from './PrivateRoutes';

const Routes: FC = () => {
  const dispatch = useAppDispatch();
  const { isAuthorized, isJoyride } = useAppSelector((state) => state.auth, shallowEqual);
  const { user } = useAppSelector((state) => state.auth);

  const handleJoyrideCallback = (data: any) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      dispatch(_setJoyride());
    }
  };

  if (user?.company?.is_maintance)
    return (
      <Switch>
        <Route>
          <Maintance />
        </Route>
      </Switch>
    );

  if (user?.company?.late_payment)
    return (
      <Switch>
        <Route>
          <LatePayment />
        </Route>
      </Switch>
    );

  return (
    <Switch>
      <Route path="/distance-sales-contract" component={DistanceSalesContract} exact />
      <Route path="/privacy-and-security" component={PrivacyAndSecurity} exact />
      <Route path="/cancel-refund" component={CancelRefund} exact />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <MasterLayout>
          <Joyride
            steps={steps}
            continuous={true}
            run={isJoyride}
            disableScrolling
            showSkipButton
            showProgress
            locale={{ back: 'Geri', close: 'Kapat', last: 'Son', next: 'Sonraki', open: 'İletişim Kutusunu Aç', skip: 'Geç' }}
            callback={handleJoyrideCallback}
            floaterProps={{
              styles: {
                wrapper: {
                  zIndex: 1000,
                },
              },
            }}
            styles={{
              options: {
                zIndex: 1000,
                arrowColor: 'white',
                backgroundColor: 'white',
                textColor: '#3F4254',
                primaryColor: '#0095E8',
                // #D9214E
              },
              spotlight: {
                maxWidth: 275,
              },
            }}
          />
          <PrivateRoutes />
          <Route path="/error" component={ErrorsPage} />
        </MasterLayout>
      )}
    </Switch>
  );
};

export { Routes };
